.mm-dairy-details {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 28px 28px 28px;

    & &__farm-name {
        display: flex;
        justify-content: flex-end;

        .link {
            display: flex;
            justify-content: center;
            cursor: pointer;

            span {
                font-size: 20px;
                font-weight: 900;
                line-height: 26px;
                color: #1c1c24;
            }
        }
    }

    & &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 40px;
        overflow-y: auto;
    }

    & &__first-block,
    & &__second-block {
        display: flex;
        flex-direction: column;
        gap: 19px;
    }

    & &__buttons-bar {
        display: flex;
        flex: 1;
        flex-direction: row;
        gap: 20px;
    }

    & &__edit-dairy-button {
        cursor: pointer;
        flex: 1;
    }

    & &__add-device-button {
        cursor: pointer;
        min-height: 40px;
    }

    & &__adv-button {
        background-color: #ab78c7;
        cursor: pointer;
        flex: 1;
    }
}

@media only screen and (min-width: 600px) {
    .mm-dairy-details {
        & &__content {
            flex-direction: row;
            overflow: hidden;
        }

        & &__first-block {
            width: 40%;
            overflow-y: auto;
        }

        & &__second-block {
            flex: 1;
            overflow-y: auto;
        }
    }
}