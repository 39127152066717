.mm-dairy-card {
    padding: 15px 17px 18px 17px;
    background-color: #fefeff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;

    & &__info {
        flex: 1;
        padding-right: 17px;
    }

    & &__text {
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
    }

    & &__text--header {
        font-weight: 900;
    }

    & &__content {
        display: flex;
        gap: 28px;
    }

    & &__icon {
        flex: 0;
        user-select: none;
    }

    & &__icon--alert {
        font-size: 36px;
        color: #c83e4d;
    }

    & &__icon--arrow {
        font-size: 38px;
        cursor: pointer;
    }
}