.mm-equipment-card {
    padding: 13px 0 18px 16px;
    background-color: #fefeff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    flex-shrink: 0;

    & &__text {
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
        display: flex;
        align-items: center;
    }

    & &__row {
        padding-right: 16px;
        border-bottom: 1px solid #c6c6c8;
        display: flex;
        justify-content: space-between;
    }

    & &__row:first-child &__text {
        font-weight: 900;
    }

    & &__row:first-child,
    & &__row:last-child {
        border-bottom: none;
    }

    & &__row:not(:first-child) {
        padding-top: 20px;
    }
}
