.login-page {
    height: 100%;
    background: url(../../assets/img/BG.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &__overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 350px;
        width: 622px;
        border-radius: 6px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
        backdrop-filter: blur(15px);
    }

    &__form-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 350px;
        width: 230px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        height: 61%;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        width: 230px;
    }

    &__logo {
        height: 39%;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        padding-top: 22px;
        padding-bottom: 22px;
    }

    &__login-input {
        margin-bottom: 16px;
    }

    &__password-input {
        margin-bottom: 14px;
    }

    &__error-message {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        color: #cd0006;
    }

    &__login-button {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: 34px;
    }
}
