.mm-side-panel {
  &__profile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 100%;
    padding-top: 20px;

    &__section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;

      &__title {
        font-weight: bold;
      }

      &__content {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 10px;
    }
  }
}