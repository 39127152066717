.mm-side-panel {
  background-color: #ebf1f6;
  width: 100%;
  padding-left: 15px;
  padding-top: 74px;
  border-right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  &__header {
    text-align: left;
    padding-top: 16px;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
  }

  &__divider {
    width: 90%;
    align-self: flex-start;
  }

  &__content {
    flex: 1;
  }
}

@media only screen and (min-width: 600px) {
  .mm-side-panel {
    width: 25vw;
    min-width: 270px;
    padding-top: 0;
    border-right: 1px solid #a6a6a9;
  }
}