.mm-dairy-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
    border: 2px solid #fafaff;
    cursor: pointer;

    &--offline,
    &--with-alerts,
    &--no-visible-alerts,
    &--no-alerts

    /*,
    &--manual,
    &--current*/
        {
        margin-top: -15px;
        margin-left: -15px;
        height: 30px;
        width: 30px;
    }

    &--offline {
        background-color: #c6c6c8;
        color: #000;
    }

    &--with-alerts {
        background-color: #b12e2e;
        color: #fafaff;
    }

    &--no-visible-alerts {
        background-color: #4b87b1;
        color: #000;
        border: 1px solid #a33934;
    }

    &--no-alerts {
        background-color: #5985ad;
        color: #000;
    }

    &--disconnected {
        height: 15px;
        width: 15px;
        background-color: #680c9b;
    }

    // &--manual {
    //     background-color: #fafaff;
    //     position: absolute;
    //     z-index: 2;
    //     color: #680C9B;
    //     font-size: 18pt;
    //     border: 2px solid #680C9B;
    // }

    // &--current {
    //     background-color: royalblue;
    //     position: absolute;
    //     z-index: 1;
    //     height: 20px;
    //     width: 20px;
    //     box-shadow: 1px 1px 10px 2px #4169e1;
    // }

    &__tooltip {
        cursor: grab;
        position: absolute;
        background: #eaf1f6 0% 0% no-repeat padding-box;
        color: #707071;
        font-size: 18px;
        font-weight: 400;
        border: 1px solid #fefeff;
        border-radius: 4px;
        white-space: nowrap;
        padding: 4px;
        margin-top: -72px;
        left: -15px;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 15px;
            margin-left: -8px;
            border-width: 7px;
            border-style: solid;
            border-color: #fefeff transparent transparent transparent;
        }
    }
}