.mm-chemical-graphs {
    height: 100%;
    display: flex;
    flex-direction: column;

    & &__header {
        display: flex;
        min-height: 40px;
        flex-direction: column-reverse;
        width: 100%;
    }

    & &__text {
        display: flex;
        padding-top: 5px;
        font-size: 16px;
    }

    & &__title,
    & &__stats {
        font-family: 'Roboto';
        line-height: 26px;
        letter-spacing: 0px;
        color: #1c1c24;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & &__title {
        font-weight: 700;
    }

    & &__stats {
        font-weight: 300;
        padding-left: 10px;
        overflow: hidden;
    }

    & &__buttons {
        margin-left: auto;
    }

    & &__content {
        flex: 1;
        margin-top: 12px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 11px;
        padding: 15px;
        position: relative;
    }

    & &__no-data {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .mm-button-group {
        width: 226px;
        background: #ebf1f6 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 10px;
        display: flex;
        overflow: hidden;
        cursor: pointer;

        span {
            width: 50%;
            text-align: center;
            font-size: 16px;
            font-family: 'Roboto';
            font-weight: 500;
            line-height: 29px;
            letter-spacing: 0px;
            color: #1c1c24;
        }

        span.active {
            background-color: #849fbc;
        }

        span:first-child {
            border-right: 1px solid #707070;
        }
    }
}

@media only screen and (min-width: 600px) {
    .mm-chemical-graphs {
        & &__header {
            flex-direction: row;
            align-items: center;
        }

        & &__text {
            padding-top: 0;
            font-size: 16px;
        }
    }
}