.mm-contact-card {
    padding: 15px 17px 18px 17px;
    background-color: #fefeff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;


    & &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }

    & &__header-text {
        font-weight: 900;
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
        margin-bottom: 11px;
    }

    

    & &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }

    & &__title,
    & &__name,
    & &__phone {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
    }

    & &__phone {
        margin-left: auto;
    }
}