.mm-equipment-qr-code-reader {
    position: absolute;
    z-index: 1700;
    inset: 0;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & &__button-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}