.nav {
    background-color: #ebf1f6;
    box-shadow: 0px -3px 6px #00000029;
    padding: 12px;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    z-index: 1001;

    &__logo-wrapper {
        height: 46px;
    }

    &__logo {
        height: 100%;
        margin-left: 5px;
    }

    & &__buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 10px;
    }

    & &__filters-button,
    & &__filters-button-active,
    & &__refresh-button,
    & &__alerts-button,
    & &__profile-button,
    & &__add-dairy-button {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;

        span {
            color: #f8f8ff;
        }
    }

    & &__filters-button {
        background-color: #c0c0c0;
    }

    & &__filters-button-active {
        background-color: #4797dd;
    }

    & &__alerts-button {
        background-color: #c83e4d;
    }

    & &__profile-button {
        background-color: #ab78c7;
    }

    & &__refresh-button {
        background-color: #4797dd;
    }

    & &__add-dairy-button {
        background-color: #27a145;
    }
}

.mm-side-panel {
    &__container {
        z-index: 1000;
        position: absolute;
        bottom: 74px;
        left: 0px;
        height: 100%;
        width: 100%;

        &--hidden {
            display: none;
        }
    }
}

@media only screen and (min-width: 600px) {
    .nav {
        box-shadow: 0px 3px 6px #00000029;
        flex-direction: column;

        &__logo-wrapper {
            margin-bottom: 25px;
        }

        & &__buttons {
            margin-top: auto;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
    }

    .mm-side-panel {
        &__container {
            bottom: 0px;
            left: 76px;
            width: auto;
        }
    }
}