.mm-nav-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    & &__accordion {
        width: 100%;
        background-color: #fff;

        .accordion__button {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            padding: 10px 18px;

            .mm-switch {
                margin-left: auto;
            }
        }

        .accordion__button-label {
            font-size: 16px;
            font-weight: 500;
        }
    }

    & &__button {
        width: 80%;
    }
}
