.mm-pagination {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    gap: 7px;
    list-style: none;
    user-select: none;
    cursor: pointer;

    a {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #4797dd;
        color: #4797dd;
    }

    & &__link {
        font-weight: bold;
    }

    & &__link--active a {
        color: #fff;
        background: #4797dd;
    }

    & &__link--disabled a {
        color: rgb(198, 197, 202);
        border: 1px solid rgb(198, 197, 202);
        cursor: not-allowed;
    }
}
