.mm-dairies-map-view {
    height: 100%;
    position: relative;

    & &__button {
        background: none rgb(255, 255, 255);
        border: 0px;
        margin: 10px;
        padding: 0px 15px;
        text-transform: none;
        appearance: none;
        user-select: none;
        border-radius: 2px;
        height: 40px;
        box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
        overflow: hidden;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 999;
        cursor: pointer;
        font-size: 18px;
        font-family: Roboto, Arial, sans-serif;
    }
}