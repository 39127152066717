.mm-button {
    background: #4797dd 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
    color: #f8f8ff;
    outline: none;
}
