.mm-edit-milk-tank-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    & &__header {
        text-align: left;

        &__title {
            font-size: 20px;
            font-weight: 900;
            line-height: 26px;
            color: #1c1c24;
            display: flex;
            gap: 5px;
        }

        &__subtitle {
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            color: #1c1c24;
            display: flex;
            gap: 5px;
        }
    }

    & &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 40px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    & &__first-block {
        display: flex;
        flex-direction: column;
    }

    & &__footer {
        display: flex;
        justify-content: space-between;

        &__actions {
            display: flex;
            gap: 5px;
        }
    }
}

@media only screen and (min-width: 600px) {
    .mm-edit-milk-tank-form {
        & &__content {
            flex-direction: row;
        }

        & &__first-block,
        & &__second-block {
            flex: 1;
        }
    }
}