.mm-edit-dairy-location-map-view {
  position: absolute;
  margin-left: -28px;
  margin-top: -15px;
  width: 100%;
  height: calc(100% - 76px);
  z-index: 1;

  & &__buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: absolute;
    width: 100%;
    padding: 10px;
  }

  & &__button--current_location,
  & &__button--close {
    background: none rgb(255, 255, 255);
    border: 0px;
    text-transform: none;
    appearance: none;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    overflow: hidden;
    top: 0px;
    z-index: 999;
    cursor: pointer;
  }

  & &__button--current_location {
    padding: 0;
    width: 40px;
  }

  & &__button--close {
    width: 60px;
    font-size: 18px;
    font-family: Roboto, Arial, sans-serif;
  }
}

@media only screen and (min-width: 600px) {
  .mm-edit-dairy-location-map-view {
    width: calc(100% - 76px);
    height: 100%;
  }
}