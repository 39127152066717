.mm-switch-list {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }

    & &__item-label {
        font-size: 16px;
    }
}
