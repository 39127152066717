.mm-input {
    height: 25px;
    padding: 0 13px 0 13px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #1c1c24;
    outline: none;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #c6c6c8;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #c6c6c8;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #c6c6c8;
    }
}
