.mm-custom-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 700;
    border: 2px solid #fafaff;
    cursor: pointer;

    &--manual,
    &--current {
        margin-top: -15px;
        margin-left: -15px;
        height: 30px;
        width: 30px;
    }

    &--manual {
        background-color: #fafaff;
        position: absolute;
        z-index: 2;
        color: #680C9B;
        font-size: 18pt;
        border: 2px solid #680C9B;
    }

    &--current {
        background-color: royalblue;
        position: absolute;
        z-index: 1;
        height: 20px;
        width: 20px;
        box-shadow: 1px 1px 10px 2px #4169e1;
    }
}