.mm-temperature-graphs {
    height: 100%;
    display: flex;
    flex-direction: column;

    & &__header {
        flex: 0;
        display: flex;
        align-items: flex-end;
        min-height: 40px;
    }

    & &__title {
        padding-left: 15px;
        font-family: 'Roboto';
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0px;
        color: #1c1c24;
    }

    & &__content {
        flex: 1;
        margin-top: 12px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 11px;
        padding: 15px;
        position: relative;
    }

    & &__no-data {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
