.mm-alert-message {
    border-radius: 10px;
    background-color: #fff700;
    font-size: 16px;
    line-height: 21px;
    color: #292929;
    padding: 10px 15px;

    flex-shrink: 0;
    width: 90%;
    max-width: fit-content;
   // height: 66px;

    line-height: 1.65;
    overflow: hidden;
    text-overflow: ellipsis;

    & &__title {
        font-weight: 500;
    }

    & &__msg {
        padding-left: 5px;
        font-weight: 300;
    }    
}

.mm-alert-message:only-child {
    width: 100%;
    max-width: none;
}