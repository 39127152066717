.mm-checkbox {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    color: #1c1c24;

    input {
        margin-right: 17px;
    }
}
