.mm-temperature-sensor-tile {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 226px;
    height: 226px;
    text-align: center;
    padding: 6px 6px 6px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 11px;
    cursor: pointer;
    position: relative;

    &--active {
        background: transparent radial-gradient(circle at center, #ffffff 35%, #78BEFD) no-repeat padding-box;
        height: 255px;
    }

    & &__equipment-name {
        flex: 0;
        font: normal normal 900 14px/19px 'Roboto';
        letter-spacing: 0px;
        color: #161414;
    }
    
    & &__alert {        
        position: absolute;
        top: 30%; left: 0; right: 0; bottom: 65%;
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 64pt;
        color:#C83F4D;
    }
    
    & &__menu {
        text-align: right;
        padding-left: 12px;
        padding-right: 12px;
    }

    & &__button {
        height: 32px;
        cursor: pointer;
    }

}
