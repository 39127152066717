.layout {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;

    &>main {
        flex: 1;
        overflow: hidden;
    }
}

@media only screen and (min-width: 600px) {
    .layout {
        flex-direction: row;
    }
}