.mm-alerts-list {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-right: -15px;
    padding-right: 15px;
}

.mm-alerts-list-item {
    padding: 15px 17px 18px 17px;
    background-color: #fefeff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;

    & &__title {
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
        display: flex;
        align-items: center;
        font-weight: 900;
    }

    & &__subtitle {
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
        display: flex;
        align-items: center;
    }

    & &__timestamp {
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
        display: flex;
        align-items: center;
    }

    & &__text {
        font-size: 16px;
        line-height: 21px;
        color: #1c1c24;
        display: flex;
        align-items: center;
        padding-top: 8px;
    }

    & &__row {
        padding-right: 16px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #c6c6c8;
    }

    & &__content {
        padding-top: 8px;
        display: flex;
        justify-content: left;
    }

    & &__icon {
        padding-right: 16px;
        font-size: 64px;
    }
}
