.mm-edit-dairy-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    & &__header {
        flex: 0;
        text-align: center;
        font-size: 20px;
        font-weight: 900;
        line-height: 26px;
        color: #1c1c24;
    }

    & &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 40px;
    }

    & &__first-block {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    & &__second-block {
        flex: 1;
    }

    & &__footer {
        flex: 0;
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 600px) {
    .mm-edit-dairy-form {
        & &__content {
            flex-direction: row;
        }

        & &__first-block {
            width: 40%;
        }
    }
}