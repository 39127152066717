.mm-chemical-sensor-tile {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 172px;
    height: 226px;
    padding: 6px 0;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 11px;
    cursor: pointer;

    &--active {
        background: transparent radial-gradient(circle at center, #ffffff 35%, #78BEFD) no-repeat padding-box;
        height: 255px;
    }

    & &__capacity,
    & &__remaining,
    & &__chemical-name {
        flex: 0;
        font: normal normal 900 14px/19px 'Roboto';
        letter-spacing: 0px;
        color: #161414;
    }

    & &__image {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }

    & &__menu {
        text-align: right;
        padding-left: 12px;
        padding-right: 12px;
    }

    & &__button {
        height: 32px;
        cursor: pointer;
    }
}
