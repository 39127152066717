.mm-dairies-list-filter {
    display: flex;
    gap: 40px;
    flex-direction: row;
    align-items: center;
    padding-bottom: 28px;

    & &__search {
        flex: 1;
        display: flex;
        align-items: center;
        border: 1px solid #c6c6c6;
        border-radius: 5px;
        white-space: nowrap;
        height: 36px;
    }

    & &__search--input {
        flex: 1;
        border: none;
        width: 100%;
        font-size: 18px;
    }

    & &__search--input::placeholder {
        color: #bbb;
    }

    & &__search--icon-button {
        text-indent: -999px;
        overflow: hidden;
        width: 40px;
        padding: 0;
        margin: 0;
        border: 1px solid transparent;
        border-radius: inherit;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
        cursor: pointer;
        opacity: 0.7;
    }

    & &__search--icon-button:hover {
        opacity: 1;
    }

    & &__link {
        display: flex;
        width: 100px;
        align-items: center;
        margin-left: auto;
        font-weight: 900;
        font-size: 18px;
        color: #4b4b4b;
        cursor: pointer;
    }

    & &__link--text {
        white-space: nowrap;
        text-decoration: underline;
    }

    & &__link--icon {
        text-decoration: none;
    }
}