.mm-dairy-cluster-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 400;
    border: 2px solid #fefeff;

    color: #fafaff;
    cursor: pointer;

    &--no-alerts {
        background-color: #5985ad;
    }

    &--with-alerts {
        background-color: #b12e2e;
    }

    &__tooltip {
        position: absolute;
        background: #eaf1f6 0% 0% no-repeat padding-box;
        color: #707071;
        font-size: 18px;
        border: 1px solid #fefeff;
        border-radius: 8px;
        padding: 8px;
        z-index: 1;

        ul {
            width: 167px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            border-width: 7px;
            border-style: solid;
            border-color: #fefeff transparent transparent transparent;
        }

        &__item-text {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__item-link {
            text-decoration: underline;
            cursor: pointer;
            list-style: square inside;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__item-more {
            padding-left: 24px;
        }
    }
}