html {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

body {
    height: 100%;
    overflow-x: hidden;
    background-color: #f8f8ff;
    color: #1c1c24;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.root {
    height: 100%;
}

h1 {
    color: #1c1c24;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.3;
}

h2 {
    color: #1c1c24;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
}

h3 {
    color: #1c1c24;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
    line-height: 1.3;
}

h4 {
    color: #1c1c24;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    line-height: 1.3;
}

h5 {
    color: #1c1c24;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    line-height: 1.3;
}

h6 {
    color: #1c1c24;
    font-size: 10px;
    font-weight: 400;
    margin-top: 5px;
    line-height: 1.3;
}

p {
    color: #1c1c24;
    font-size: 12px;
    font-weight: 400;
}

a {
    cursor: pointer;
    text-decoration: none !important;
}
