.mm-dairy-sensors {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 28px 28px 28px;

    & &__farm-name {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        .link {
            display: flex;
            justify-content: end;
            cursor: pointer;
            overflow: hidden;

            span {
                font-size: 20px;
                font-weight: 900;
                line-height: 26px;
                color: #1c1c24;
            }

            &__name {
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .last-reading-status__online,
        .last-reading-status__offline {
            color: #f8f8ff;
            display: flex;
            gap: 5px;
            align-items: center;
            border-radius: 14px;
            padding: 2px 5px 2px 5px;
            white-space: nowrap;
        }

        .last-reading-status__online {
            background-color: #27a145;
        }

        .last-reading-status__offline {
            background-color: #c83e4d;
        }
    }

    & &__alert {
        height: 66px;
        display: flex;
        flex-direction: row;
        gap: 22px;
        overflow-x: auto;
    }

    & &__graphs {
        flex: 1;
    }
}