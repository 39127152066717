$switchToggleHeight: 31px;
$switchToggleWidth: 51px;

.mm-switch {
    display: flex;
    align-items: center;

    &__checkbox {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: $switchToggleWidth;
        height: $switchToggleHeight;
        background: grey;
        border-radius: $switchToggleWidth;
        position: relative;
        transition: background-color 0.2s;
    }

    &__checkbox:checked + &__toggle {
        background-color: #34c759;
    }

    &__button {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: $switchToggleHeight - 4;
        height: $switchToggleHeight - 4;
        border-radius: $switchToggleHeight - 4;
        transition: 0.2s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    &__checkbox:checked + &__toggle &__button {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

    &__toggle:active &__button {
        width: ($switchToggleHeight - 4) + (($switchToggleHeight - 4) / 3);
    }

    &__label {
        margin-left: 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        color: #1c1c24;
        cursor: pointer;
    }
}
